import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import ScrollToTop from 'common/components/ScrollToTop';

class ScrollToTopDemo extends Component {
  render() {
    const props = {
      pageYOffsetThreshold: 0
    };

    return (
      <div>
        <h5>Scroll down to see example</h5>
        <div className="styleguide-code-example">
          <pre>
{
`const props = ${JSON.stringify(props)};
return (<ScrollToTop {...props} />);
`
}
          </pre>
        </div>
        <div className="styleguide-example">
          <ScrollToTop {...props} />
        </div>
      </div>
    );
  }
}

$(() => {
  ReactDOM.render(
    React.createElement(ScrollToTopDemo),
    document.getElementById('component-demo')
  );
});
